import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}
class Queues {
  get(id) {
    return ApiService.get(url, "queues/" + id);
  }

  getAll(params, apiKey = "", cancelToken) {
    let optionalHeaders = {};

    if (apiKey.length) optionalHeaders = this.formatOptionalHeaders(apiKey);
    let options = new URLSearchParams(params).toString();
    if (cancelToken) {
      optionalHeaders.cancelToken = cancelToken;
    }
    return ApiService.get(url, "queues?" + options, optionalHeaders);
  }

  store(data) {
    return ApiService.post(url + "/queues", data);
  }

  update(queueId, data, optionalHeaders) {
    return ApiService.put(url + "/queues/" + queueId, data, optionalHeaders);
  }

  delete(id, apiKey = "") {
    let optionalHeaders = {};
    if (apiKey.length) optionalHeaders = this.formatOptionalHeaders(apiKey);
    return ApiService.delete(url + "/queues/" + id, optionalHeaders);
  }

  formatOptionalHeaders(apiKey) {
    return {
      headers: {
        "x-api-key": apiKey
      }
    };
  }
}

export default new Queues();
