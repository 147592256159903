<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">{{ $t("client.stepActivateQueues") }}:</h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>{{ $t("client.stepActivateQueuesIntro") }}</p>
      </div>
    </div>
    <div class="row align-items-stretch">
      <div v-for="(queue, i) in queues" :key="i" class="col-md-6 car col-xl-4">
        <div
          class="card card-custom wave mb-8 mb-lg-0 h-100"
          :class="[queue.active ? 'wave-success wave-animate' : 'wave-danger']"
        >
          <div class="card-body position-relative">
            <div class="d-flex flex-column h-100">
              <div class="col h5 mb-3">{{ queue.name }}</div>
              <div class="col-auto">
                <p>
                  {{ queue.instances }}
                  {{
                    queue.instances === 1
                      ? $t("client.queueInstance")
                      : $t("client.queueInstances")
                  }}
                </p>
              </div>
              <div class="col-auto">
                <button
                  v-if="!queue.active"
                  class="btn btn-light-success"
                  :disabled="isBusy"
                  :class="{
                    'spinner spinner-white spinner-right':
                      isBusy && updatingId === queue.id
                  }"
                  @click="updateQueue(queue, 1)"
                >
                  {{ $t("client.activate") }}
                </button>
                <button
                  v-else
                  class="btn btn-light-danger"
                  :disabled="isBusy"
                  :class="{
                    'spinner spinner-white spinner-right':
                      isBusy && updatingId === queue.id
                  }"
                  @click="updateQueue(queue, 0)"
                >
                  {{ $t("client.deactivate") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Queues from "@/components/Projects/Settings/Queues/queues";
import ApiService from "@/core/services/api.service";
export default {
  components: {},
  props: ["client"],
  data() {
    return {
      queues: [],
      updatingId: null,
      isBusy: false
    };
  },
  computed: {
    isClient: function () {
      return Object.keys(this.client).length;
    },
    requestHeaders: function () {
      ApiService.removeHeader("x-partner-key");
      let headers = {};
      if (this.client.api_token) {
        let apiKey = this.client.api_token;
        headers = {
          headers: {
            "x-api-key": `Bearer ${apiKey}`
          }
        };
      }
      return headers;
    }
  },
  mounted() {
    if (this.isClient) this.getQueues();
  },
  methods: {
    isReady() {
      return true;
    },
    getQueues() {
      this.isBusy = true;
      const params = { size: 0 };
      Queues.getAll(params, this.requestHeaders)
        .then(response => {
          this.queues = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
          this.isBusy = false;
        });
    },
    updateQueue(queue, activeState) {
      this.isBusy = true;
      this.updatingId = queue.id;
      const data = {
        name: queue.name,
        active: activeState,
        instances: queue.instances
      };
      Queues.update(queue.id, data, this.requestHeaders)
        .then(() => {
          this.getQueues();
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
