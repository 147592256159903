var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("client.stepActivateQueues"))+":")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('p',[_vm._v(_vm._s(_vm.$t("client.stepActivateQueuesIntro")))])])]),_c('div',{staticClass:"row align-items-stretch"},_vm._l((_vm.queues),function(queue,i){return _c('div',{key:i,staticClass:"col-md-6 car col-xl-4"},[_c('div',{staticClass:"card card-custom wave mb-8 mb-lg-0 h-100",class:[queue.active ? 'wave-success wave-animate' : 'wave-danger']},[_c('div',{staticClass:"card-body position-relative"},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"col h5 mb-3"},[_vm._v(_vm._s(queue.name))]),_c('div',{staticClass:"col-auto"},[_c('p',[_vm._v(" "+_vm._s(queue.instances)+" "+_vm._s(queue.instances === 1 ? _vm.$t("client.queueInstance") : _vm.$t("client.queueInstances"))+" ")])]),_c('div',{staticClass:"col-auto"},[(!queue.active)?_c('button',{staticClass:"btn btn-light-success",class:{
                  'spinner spinner-white spinner-right':
                    _vm.isBusy && _vm.updatingId === queue.id
                },attrs:{"disabled":_vm.isBusy},on:{"click":function($event){return _vm.updateQueue(queue, 1)}}},[_vm._v(" "+_vm._s(_vm.$t("client.activate"))+" ")]):_c('button',{staticClass:"btn btn-light-danger",class:{
                  'spinner spinner-white spinner-right':
                    _vm.isBusy && _vm.updatingId === queue.id
                },attrs:{"disabled":_vm.isBusy},on:{"click":function($event){return _vm.updateQueue(queue, 0)}}},[_vm._v(" "+_vm._s(_vm.$t("client.deactivate"))+" ")])])])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }