<template>
  <div class="offset-md-2 col-md-8 card card-custom">
    <div class="card-body position-relative">
      <div class="text-right">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: routeToTable })"
        >
          {{ $t("client.cancelCreate") }}
        </button>
      </div>
      <div
        id="kt_wizard_clients"
        class="wizard wizard-1"
        data-wizard-state="step-content"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div
            id="wizard-steps"
            ref="wizard-steps"
            class="wizard-steps py-8 p-lg-10 overflow-x-auto justify-content-start"
          >
            <div
              v-for="(step, i) in steps"
              :id="'step-' + (i + 1)"
              :key="i"
              class="wizard-step"
              data-wizard-type="step"
            >
              <div class="wizard-label">
                <i class="wizard-icon" :class="step.icon"></i>
                <h3 class="wizard-title">
                  {{ step.label }}
                </h3>
              </div>
              <i
                v-if="i < steps.length - 1"
                class="wizard-arrow fal fa-chevron-right"
              ></i>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->
        <v-progress-linear v-if="isBusy" indeterminate />
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-8">
            <div data-wizard-type="step-content">
              <StepCreateDatabase ref="step-1" @set-params="setParamsStep1" />
            </div>
            <div data-wizard-type="step-content">
              <StepAddClientEntry
                ref="step-2"
                :key="addClientKey"
                :client-name="clientName"
                :partner="partnerId"
                @client-added="setClientStep2"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepInitializeMongo
                ref="step-3"
                :db="databaseConfig"
                :uuid="uuid"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepTenantStorage ref="step-4" :uuid="uuid" />
            </div>
            <div data-wizard-type="step-content">
              <StepTenantConnections
                ref="step-5"
                :db="databaseConfig"
                :client-id="clientId"
                :client-data="clientData"
                :uuid="uuid"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepFtpUser
                ref="step-6"
                :uuid="uuid"
                :client-data="clientData"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepInitializeTenant
                ref="step-7"
                :client-id="clientId"
                :client-data="clientData"
                @client-updated="updateClientStep7"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepActivateQueues
                ref="step-8"
                :key="activateQueuesKey"
                :client="client"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepServiceDaemons ref="step-9" />
            </div>
          </div>
        </div>
        <!--begin: Wizard Actions -->
        <div
          class="d-flex justify-content-between border-top pt-10 wizard-actions"
        >
          <div class="mr-2">
            <button
              class="btn btn-light-primary font-weight-bold text-uppercase px-sm-9 py-sm-4 px-3 py-2"
              data-wizard-type="action-prev"
              :disabled="isBusy"
              @click="previousStep"
            >
              {{ $t("general.previousStep") }}
            </button>
          </div>
          <div>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-submit"
              :disabled="isBusy"
              @click="finish"
            >
              {{ $t("client.wizardFinish") }}
            </button>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-sm-9 py-sm-4 px-3 py-2"
              data-wizard-type="action-next"
              :disabled="isBusy"
              @click="nextStep"
            >
              <span>{{ $t("general.nextStep") }}</span>
            </button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </div>
    </div>
  </div>
</template>

<script>
import KTWizard from "@/assets/js/components/wizard";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { bus } from "@/main";
import $ from "jquery";

// STEPS
import StepCreateDatabase from "@/components/Admins/Clients/Steps/StepCreateDatabase";
import StepAddClientEntry from "@/components/Admins/Clients/Steps/StepAddClientEntry";
import StepInitializeMongo from "@/components/Admins/Clients/Steps/StepInitializeMongo";
import StepTenantStorage from "@/components/Admins/Clients/Steps/StepTenantStorage";
import StepTenantConnections from "@/components/Admins/Clients/Steps/StepTenantConnections";
import StepFtpUser from "@/components/Admins/Clients/Steps/StepFtpUser";
import StepInitializeTenant from "@/components/Admins/Clients/Steps/StepInitializeTenant";
import StepActivateQueues from "@/components/Admins/Clients/Steps/StepActivateQueues";
import StepServiceDaemons from "@/components/Admins/Clients/Steps/StepServiceDaemons";

export default {
  components: {
    StepCreateDatabase, // Step 1
    StepAddClientEntry, // Step 2
    StepInitializeMongo, // Step 3
    StepTenantStorage, // Step 4
    StepTenantConnections, // Step 5
    StepFtpUser, // Step 6
    StepInitializeTenant, // Step 7
    StepActivateQueues, // Step 8
    StepServiceDaemons // Step 9
  },
  data() {
    return {
      routeToTable: "adminTenants",
      routeToDetails: "adminTenantsDetails",
      routeBreadcrumb: "/admin/clients",
      partnerId: null,
      wizard: null,
      steps: [
        {
          label: this.$t("client.stepCreateDatabase"),
          icon: "fal fa-rocket-launch"
        },
        {
          label: this.$t("client.stepAddClientEntry"),
          icon: "fal fa-user-plus"
        },
        {
          label: this.$t("client.stepInitializeMongo"),
          icon: "fal fa-database"
        },
        {
          label: this.$t("client.stepTenantStorage"),
          icon: "fal fa-box-open-full"
        },
        {
          label: this.$t("client.stepTenantConnections"),
          icon: "fal fa-cloud-check"
        },
        { label: this.$t("client.stepFtpUser"), icon: "fal fa-key" },
        {
          label: this.$t("client.stepInitializeTenant"),
          icon: "fal fa-user-check"
        },
        {
          label: this.$t("client.stepActivateQueues"),
          icon: "fal fa-clipboard"
        },
        {
          label: this.$t("client.stepServiceDaemons"),
          icon: "fal fa-stopwatch"
        }
      ],
      activeStep: 1,
      isBusy: false,
      enableNext: false,
      // Step 1
      dbConfig: {
        database: "",
        username: "",
        password: ""
      },
      clientName: "",
      // Step 2
      clientAdded: false,
      client: {},
      // Component keys
      addClientKey: 1,
      activateQueuesKey: 1
    };
  },
  computed: {
    uuid: function () {
      return this.client.uuid;
    },
    clientId: function () {
      return this.client.id;
    },
    clientData: function () {
      return this.client;
    },
    databaseConfig: function () {
      return this.dbConfig;
    }
  },
  mounted() {
    this.getRouteParams();
    this.initializeWizard();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminTenants"),
        route: this.routeBreadcrumb
      },
      {
        title: this.$t("client.clientCreate"),
        route: this.routeBreadcrumb + "/create"
      }
    ]);
    bus.$on("next-step", this.onNextStep);
  },
  destroyed() {
    bus.$off("next-step", this.onNextStep);
  },
  methods: {
    onNextStep(value) {
      this.enableNext = value;
    },
    getRouteParams() {
      const partnerId = this.$route.params.partnerId;
      if (partnerId !== undefined) {
        this.partnerId = partnerId;
      }

      const routeTable = this.$route.params.routeToTable;
      if (routeTable !== undefined) {
        this.routeToTable = routeTable;
      }

      const routeDetails = this.$route.params.routeToDetails;
      if (routeDetails !== undefined) {
        this.routeToDetails = routeDetails;
      }

      const routeBreadcrumb = this.$route.params.routeBreadcrumb;
      if (routeBreadcrumb !== undefined) {
        this.routeBreadcrumb = routeBreadcrumb;
      }
    },
    initializeWizard() {
      let me = this;
      this.$nextTick().then(() => {
        me.wizard = new KTWizard("kt_wizard_clients", {
          clickableSteps: false
        });
      });
    },
    nextStep() {
      this.wizard.stop();
      let step = this.wizard.getStep();
      let goNext = this.$refs["step-" + step].isReady();
      if (!goNext) return;
      switch (step) {
        case 7:
          this.activateQueuesKey++;
          break;
      }
      this.scrollToStep(step + 1);
      this.wizard.resume();
    },
    previousStep() {
      this.wizard.stop();
      let step = this.wizard.getStep();
      this.scrollToStep(step - 1);
      this.wizard.resume();
    },
    scrollToStep(step) {
      let el = $("#step-" + step)[0];
      let elLeft = el.offsetLeft;
      let container = $("#wizard-steps");
      container.animate({ scrollLeft: elLeft }, 300);
    },
    setParamsStep1(payload) {
      this.dbConfig = payload.database;
      this.clientName = payload.clientName;
      if (!this.clientAdded) this.addClientKey++;
    },
    setClientStep2(payload) {
      this.client = payload;
      this.clientAdded = true;
    },
    updateClientStep7(payload) {
      if (payload.id === this.client.id) this.client = payload;
    },
    finish() {
      let step = this.wizard.getStep();
      let finish = this.$refs["step-" + step].isReady();
      if (finish)
        this.$router.push({
          name: this.routeToDetails,
          params: { id: this.clientId }
        });
      bus.$emit("reloadHeaderFilter");
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/sass/pages/wizard/wizard-1";

#wizard-steps {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.wizard-actions {
  position: sticky;
  background: #fff;
  padding-bottom: 20px;
  bottom: 0;
}
.wizard-icon {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wizard-title {
  height: 34px;
}
</style>
