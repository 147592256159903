<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">{{ $t("client.stepServiceDaemons") }}:</h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>{{ $t("client.stepServiceDaemonsIntro") }}</p>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>
          {{ $t("client.stepServiceDaemonsLink") }}
          <a :href="SERVER_CON" target="_blank"
            >{{ SERVER_CON }} <i class="fal fa-arrow-up-right-from-square"
          /></a>
        </p>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("client.stepServiceDaemonsDone") }}
      </label>
      <div class="col-md-9 col-lg-6">
        <span class="switch switch-sm">
          <label>
            <input v-model="$v.daemonsRestarted.$model" type="checkbox" />
            <span></span>
          </label>
          <span v-if="$v.daemonsRestarted.$error">
            <i class="text-danger icon-lg fal fa-circle-exclamation" />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["uuid"],
  data() {
    return {
      daemonsRestarted: false,
      SERVER_CON: process.env.VUE_APP_SERVER_CON
    };
  },
  validations: {
    daemonsRestarted: {
      checked(value) {
        return value;
      }
    }
  },
  computed: {
    command: function () {
      return "bash init-tenant.sh " + this.uuid;
    }
  },
  methods: {
    isReady() {
      this.$v.$touch();
      let ready = !this.$v.$invalid;
      if (!ready) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("client.stepServiceDaemonsWarning")
        });
      }
      return ready;
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    }
  }
};
</script>
