<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("client.stepInitializeTenant") }}:
        </h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>{{ $t("client.stepInitializeTenantIntro") }}</p>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <button
          class="btn btn-primary"
          :class="{ 'spinner spinner-white spinner-right': isBusy }"
          :disabled="isBusy || tenantInitialized"
          @click="initializeTenant"
        >
          {{ $t("client.stepInitializeTenantConfirm") }}
        </button>
      </div>
    </div>
    <div v-if="success" class="form-group row align-items-center mt-5">
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-success svg-icon-3x">
          <inline-svg src="media/svg/icons/Navigation/Double-check.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="">{{ $t("client.stepInitializeTenantSuccess") }}</div>
      </div>
    </div>
    <div v-if="error" class="form-group row align-items-center mt-5">
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-danger svg-icon-3x">
          <inline-svg src="media/svg/icons/Navigation/Close.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="">{{ $t("client.stepInitializeTenantError") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Tenants from "@/components/Admins/Tenants/tenants";
import {
  UPDATE_SELECTED_CLIENT,
  UPDATE_SELECTED_PARTNER_BY_ID
} from "@/core/services/store/switchArea.module";

export default {
  components: {},
  props: ["clientId", "clientData"],
  data() {
    return {
      tenant: {},
      tenantInitialized: false,
      isBusy: false,
      success: false,
      error: false
    };
  },
  methods: {
    isReady() {
      let ready = this.tenantInitialized;
      if (!ready) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("client.stepInitializeTenantWarning")
        });
        return false;
      }
      this.$emit("client-updated", this.tenant);
      return true;
    },
    async setClientAndPartner() {
      /* Set Partner */
      await this.$store.dispatch(
        UPDATE_SELECTED_PARTNER_BY_ID,
        this.clientData.partner_id
      );
      /* Set Client */
      await this.$store.dispatch(UPDATE_SELECTED_CLIENT, this.clientData);
    },
    initializeTenant() {
      this.isBusy = true;
      this.success = false;
      this.error = false;
      let id = this.clientData.tenant.id;

      Tenants.initialize(id)
        .then(response => {
          if (response.status !== 200 && response.status !== 201)
            throw new Error("Error");
          this.tenant = response.data.data;
          this.tenantInitialized = true;
          this.success = true;
          this.isBusy = false;
          this.setClientAndPartner();
        })
        .catch(error => {
          this.error = true;
          this.isBusy = false;
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
