<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">{{ $t("client.stepAddClientEntry") }}:</h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>{{ $t("client.stepAddClientEntryIntro") }}</p>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("client.clientName") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <input
          v-model="$v.client.$model"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.client.$error }"
          :readonly="clientAdded"
        />
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("client.selectPartner") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <v-select
          v-model="partnerId"
          class="form-control"
          :items="partners"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          :disabled="clientAdded || isPartner"
        />
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label"></label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <button
          class="btn btn-primary"
          :class="{ 'spinner spinner-white spinner-right': isBusy }"
          :disabled="$v.client.$invalid || isBusy || clientAdded"
          @click="storeClient"
        >
          {{ $t("client.addClient") }}
        </button>
      </div>
    </div>
    <div v-if="clientAdded" class="form-group row align-items-center mt-5">
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-success svg-icon-5x">
          <inline-svg src="media/svg/icons/Navigation/Double-check.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="text-h5 mb-3">{{ $t("client.addClientSuccessTitle") }}</div>
        <p class="mb-0">
          {{ $t("client.addClientSuccessMessage", { name: client }) }}
        </p>
      </div>
    </div>
    <div v-else-if="clientError" class="form-group row align-items-center mt-5">
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-danger svg-icon-5x">
          <inline-svg src="media/svg/icons/Navigation/Close.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="text-h5 mb-3">{{ $t("client.addClientErrorTitle") }}</div>
        <p class="mb-0">
          {{ $t("client.addClientErrorMessage", { name: client }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Client from "@/components/Admins/Clients/clients";
import Partners from "@/components/Admins/Partners/partners";
export default {
  components: {},
  props: ["clientName", "partner"],
  data() {
    return {
      client: "",
      clientAdded: false,
      clientError: false,
      isBusy: false,
      partners: [],
      partnerId: null,
      isPartner: false
    };
  },
  validations: {
    client: { required }
  },
  mounted() {
    const userType = this.$store.getters.userType;
    this.isPartner = userType === "partner";
    this.client = this.clientName;
    if (this.partner !== null) {
      this.partnerId = this.partner;
    }
    this.loadPartners();
  },
  methods: {
    isReady() {
      if (!this.clientAdded) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("client.addClientFirst")
        });
      }
      return this.clientAdded;
    },
    storeClient() {
      this.isBusy = true;
      this.clientError = false;
      this.clientAdded = false;
      const data = {
        name: this.client,
        partner_id: this.partnerId
      };
      Client.store(data)
        .then(response => {
          if (response.status !== 200 && response.status !== 201)
            throw new Error("Error");
          this.$emit("client-added", response.data.data);
          this.clientAdded = true;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title:
              typeof error.response !== "undefined"
                ? error.response.data.message
                : error
          });
          this.clientError = true;
          this.isBusy = false;
        });
    },
    loadPartners() {
      this.isBusy = true;
      Partners.getAll({ page: 1, size: 999 })
        .then(response => {
          let partners = response.data.data;
          partners.unshift({
            name: this.$t("client.noPartner"),
            id: null
          });
          this.partners = partners;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
          this.clientError = true;
          this.isBusy = false;
        });
    }
  }
};
</script>
