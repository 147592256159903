<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">{{ $t("client.stepTenantStorage") }}:</h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>{{ $t("client.stepTenantStorageIntro") }}</p>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("client.stepTenantStorageCommand") }}
      </label>
      <div class="col-lg-9 col-xl-6">
        <div class="input-group">
          <div
            class="input-group-prepend cursor-pointer"
            @click="copyText(command)"
          >
            <span class="input-group-text">
              <i class="fal fa-copy" />
            </span>
          </div>
          <input
            type="text"
            :value="command"
            class="form-control pr-0"
            readonly
          />
        </div>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("client.stepTenantStorageStorageCreated") }}
      </label>
      <div class="col-md-9 col-lg-6">
        <span class="switch switch-sm">
          <label>
            <input v-model="$v.storageCreated.$model" type="checkbox" />
            <span></span>
          </label>
          <span v-if="$v.storageCreated.$error">
            <i class="text-danger icon-lg fal fa-circle-exclamation" />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["uuid"],
  data() {
    return {
      storageCreated: false
    };
  },
  validations: {
    storageCreated: {
      checked(value) {
        return value;
      }
    }
  },
  computed: {
    command: function () {
      return "bash init-tenant.sh " + this.uuid;
    }
  },
  methods: {
    isReady() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    }
  }
};
</script>
